export default [
  {
    title: 'Community',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Students',
        route: 'community',
        resource: 'coach',
        action: 'read',
        icon: 'UsersIcon',
      },
      {
        title: 'Groups',
        route: 'groups',
        resource: 'coach',
        action: 'read',
        icon: 'UsersIcon',
      },
    ],
  },
  {
    title: 'Spider Stats',
    icon: 'BarChart2Icon',
    children: [
      {
        title: 'Summary',
        route: 'stats-summary',
        resource: 'coach',
        action: 'read',
        icon: 'BarChart2Icon',
      },
      {
        title: 'Scoring',
        route: 'scoring',
        resource: 'coach',
        action: 'read',
        icon: 'BarChart2Icon',
      },
      {
        title: 'Off The Tee',
        route: 'off-the-tee',
        resource: 'coach',
        action: 'read',
        icon: 'BarChart2Icon',
      },
      {
        title: 'To The Green',
        route: 'to-the-green',
        icon: 'BarChart2Icon',
        resource: 'coach',
        action: 'read',
      },
      {
        title: 'Greenside',
        route: 'greenside',
        resource: 'coach',
        action: 'read',
        icon: 'BarChart2Icon',
      },
      {
        title: 'Putting',
        route: 'putting',
        resource: 'coach',
        action: 'read',
        icon: 'BarChart2Icon',
      },
      {
        title: 'Strokes Gained',
        route: 'strokes-gained',
        resource: 'coach',
        action: 'read',
        icon: 'BarChart2Icon',
      },
      {
        title: 'Spider Proximity',
        route: 'spider-proximity',
        resource: 'coach',
        action: 'read',
        icon: 'BarChart2Icon',
      },
      {
        title: 'Impact',
        route: 'impact',
        resource: 'coach',
        action: 'read',
        icon: 'BarChart2Icon',
      },
      {
        title: 'Routine',
        route: 'routine',
        resource: 'coach',
        action: 'read',
        icon: 'BarChart2Icon',
      },
      {
        title: 'Wasted Shots',
        route: 'wasted-shots',
        resource: 'coach',
        action: 'read',
        icon: 'BarChart2Icon',
      },
    ],
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    tag: 'new',
    // tagVariant: 'light-success',
    icon: 'ShuffleIcon',
    resource: 'player',
    action: 'read',
  },
  {
    title: 'Subscription',
    icon: 'ShieldIcon',
    children: [
      {
        title: 'Purchase',
        route: 'subscription',
        icon: 'ShieldIcon',
        resource: 'player',
        action: 'read',
      },
      {
        title: 'List Subscriptions',
        route: 'subscriptions-list',
        icon: 'ShieldIcon',
        resource: 'player',
        action: 'read',
      },
    ],
  },
  {
    title: 'Generate Codes',
    route: 'generate-redeem-codes',
    icon: 'CodeIcon',
    resource: 'admin',
    action: 'read',
  },
  {
    title: 'Redeem Codes',
    route: 'input-redeem-codes',
    icon: 'CodeIcon',
    resource: 'player',
    action: 'read',
  },
  {
    title: 'FAQ',
    route: 'faq',
    icon: 'HelpCircleIcon',
    resource: 'player',
    action: 'read',
  },
]
