<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <b-navbar-nav class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
      <locale />
    </b-navbar-nav>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userName }}
            </p>
            <span class="user-status">{{ userRole }}</span>
          </div>
          <!-- :src="require('@/assets/images/avatars/13-small.png')" -->
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="avatar"
            class="badge-minimal"
            badge-variant="success"
          >
            <b-img
              v-if="!avatar"
              :src="appLogo"
              alt="avatar"
              height="35"
            />
          </b-avatar>
        </template>

        <b-dropdown-item
          :to="{ name: 'profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t('Profile') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          :to="{ name: 'settings' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>{{ $t('Settings') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BImg,
} from 'bootstrap-vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import logoDark from '@/assets/images/logo/avatar_light.png'
import logoLight from '@/assets/images/logo/avatar_dark.png'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Locale,
    BImg,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    userName() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData.firstname || userData.mail
    },
    userRole() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData.role
    },
    avatar() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData.avatar
    },
    appLogo() {
      if (this.$store.state.appConfig.layout.skin === 'light') {
        return logoDark
      }
      return logoLight
    },
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Reset allData value in state
      this.$store.state.spider.allData = null

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
